<template>
  <CommonPage id="qa" :title="domainConfig['IAmTitle']" classs="qa">
    <section class="qa-content pc_padding p-t-60 p-b-60">
      <h2 class="m-b-30">
        Can the Answer Book Online be trusted?
      </h2>

      <p class="m-b-60">
        Online {{domainConfig['IAmTitle']}} is intended for entertainment and inspiration only and should not be
        considered professional advice or a basis for decision-making. Use it rationally and do not
        rely excessively on {{domainConfig['IAmTitle']}}.
      </p>

      <h2 class="m-b-30">
        Is the {{domainConfig['IAmURL']}} suitable for all questions?
      </h2>

      <p class="m-b-60">
        {{domainConfig['IAmURL']}} is primarily for entertainment and inspiration and is not suitable for
        handling serious or complex issues.
      </p>

      <h2 class="m-b-30">
        What is the relationship between the online version and the paper version of the Answer
        Book?
      </h2>

      <p class="m-b-60">
        There is no relationship. Online {{domainConfig['IAmTitle']}} is inspired by the printed version of
        &quot;The Book of Answers,&quot; but all answers on this site are generated by ChatGPT and
        have no relationship with the original book. Using Online {{domainConfig['IAmTitle']}} is not limited by
        physical books; you can access it anytime, anywhere by visiting our website, making it more
        convenient and simple.
      </p>

      <h2 class="m-b-30">
        Are the provided answers completely accurate?
      </h2>

      <p class="m-b-60">
        No! The answers provided by Online {{domainConfig['IAmTitle']}} are randomly generated and are more suitable
        for leisure and entertainment rather than solving problems. The provided answers may not be
        appropriate for addressing your concerns. You can get another answer or follow your own
        thoughts to actively face and solve your problems. There is no problem that cannot be
        solved.
      </p>

      <h2 class="m-b-30">
        What should I do when I encounter a problem that I can&#39;t make a decision on?
      </h2>

      <p class="m-b-60">
        When you encounter a problem where you cannot make a decision, {{domainConfig['IAmTitle']}} can provide you
        with a randomly generated solution for inspiration. This helps you think about the problem
        from more angles. During the decision-making process, you should also listen to your inner
        voice and try to understand how your intuition guides you in making decisions.
      </p>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';

import '@/css/home.scss';
import '@/css/blog_base.scss';

export default {
  name: 'QaMain',
  components: {
    CommonPage
  },
  mixins: [common],
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },

}
</script>
